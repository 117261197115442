import { takeEvery, put, call } from 'redux-saga/effects';
import {
  CASE_DETAIL_REQUEST,
  caseDetailSuccess,
  caseDetailFail,
  MARKETS_ALLOWED_REQUEST,
  marketsAllowedSuccess,
  marketsAllowedFail,
  SAVE_VIDEO_URL_SUCCESS,
  SAVE_VIDEO_URL_REQUEST,
  saveVideoUrlFail,
  saveVideoUrlSuccess,
  GET_VIDEO_URL_REQUEST,
  getVideoUrlSuccess,
  getVideoUrlRequest,
  DELETE_VIDEO_URL_REQUEST,
  deleteVideoUrlSuccess,
  deleteVideoUrlFail,
  REQUEST_DAMAGE_COMMENTS,
  damageCommentsSuccess,
  damageCommentsFail,
  GET_ZIP_PICS_REQUEST,
  GET_CASE_LOGS_REQUEST,
  getCaseLogsSuccess,
  getCaseLogsFail
} from '../../../actions/caseActions';
import { responseOK } from 'utils';
import { deleteVideoUrl, getCase, getCaseLogs, getVideoUrl, saveVideoUrl } from 'services/cases';
import { marketsByCaseTypeAndUserRol } from 'services/catalogs';
import { getCommentsDamages } from 'services/damages';
import { getZipPictures } from 'services/files';
import { base64ToBlob } from 'utils/helperActions';
import { sendNotification } from 'helpers/helperNotifications';
import { t } from 'i18next';
export function* getCaseDetail({ payload }) {
  try {
    const { caseId } = payload;
    const response = yield call(getCase, caseId);
    if (responseOK(response)) {
      yield put(caseDetailSuccess(response.data));
    } else {
      yield put(caseDetailFail());
    }
  } catch (e) {
    yield put(caseDetailFail());
  }
}

export function* watchLogin() {
  yield takeEvery(CASE_DETAIL_REQUEST, getCaseDetail);
}

export function* getMarketsAllowed({ payload }) {
  try {
    const { caseTypeId, withAllOption } = payload;

    const response = yield call(marketsByCaseTypeAndUserRol, caseTypeId);
    if (responseOK(response)) {
      if (withAllOption) {
        yield put(
          marketsAllowedSuccess([{ id: '*****', businessName: 'Todos' }, ...response.data.data])
        );
      } else {
        yield put(marketsAllowedSuccess(response.data.data));
      }
    } else {
      yield put(marketsAllowedFail());
    }
  } catch (e) {
    yield put(marketsAllowedFail());
  }
}

export function* saveVideoUrlWorker ({ payload }) {

  try {
    const {caseId, url} = payload;

    const response = yield call(saveVideoUrl, caseId, {url});
    if(responseOK(response)){
      const {data} = response.data;
      yield put(saveVideoUrlSuccess(data));
      yield put(getVideoUrlRequest(caseId));
    }else{
      yield put(saveVideoUrlFail());
    }
  } catch (error) {
    yield put(saveVideoUrlFail());
  }
}

export function* getVideoUrlWorker({payload}){
  try {
    const response = yield call(getVideoUrl, payload);
    if(responseOK(response)){
      const {data} = response.data;
      yield put(getVideoUrlSuccess(data));
    }else{
      yield put(saveVideoUrlFail());
    }
  } catch (error) {
    yield put(saveVideoUrlFail());
  }
}

export function* deleteVideoUrlWorker({payload}){
  try {
    const { caseId, videoId } = payload;
    const response = yield call(deleteVideoUrl, videoId);
    if(responseOK(response)){
      const {data} = response.data;
      yield put(deleteVideoUrlSuccess(data));
      yield put(getVideoUrlRequest(caseId));
    }else{
      yield put(deleteVideoUrlFail());
    }
  } catch (error) {
    yield put(deleteVideoUrlFail());
  }
};

export function* getAllZipPics({payload}){
  try {
    const {caseId, sectionId,onlyFavorite, allInRoot = false, advertId = null} = payload;
    const response = yield call(getZipPictures, caseId, sectionId, onlyFavorite, allInRoot, advertId);
    if(responseOK(response)){
      sendNotification('success', t('on.file.queued'));
    }else{
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCaseLogsWorker({payload}){
  try {
    const {caseId} = payload;
    const response = yield call(getCaseLogs, caseId);
    if(responseOK(response)){
      const {data} = response.data;
      yield put(getCaseLogsSuccess(data));
    }else{
      yield put(getCaseLogsFail());
    }
  } catch (error) {
    console.log(error);
    yield put(getCaseLogsFail());
  }
};

export function* watchMarketsAllowed() {
  yield takeEvery(MARKETS_ALLOWED_REQUEST, getMarketsAllowed);
}

export function* watchSaveVideoUrl() {
  yield takeEvery(SAVE_VIDEO_URL_REQUEST, saveVideoUrlWorker);
}

export function* watchGetVideoUrl(){
  yield takeEvery(GET_VIDEO_URL_REQUEST, getVideoUrlWorker);
}

export function* watchDeleteVideoUrl(){
  yield takeEvery(DELETE_VIDEO_URL_REQUEST, deleteVideoUrlWorker);
}

export function* watchGetZipPics(){
  yield takeEvery(GET_ZIP_PICS_REQUEST, getAllZipPics);
}

export function* watchGetCaseLogs(){
  yield takeEvery(GET_CASE_LOGS_REQUEST, getCaseLogsWorker);
}
import { watchMarketsAllowed, watchLogin } from './common';
import { watchRequestDownloadFile } from './diverses/downloadFilesDiversasSagas';
import { watchRequestFiles } from './diverses/getFilesDiversesSagas';
import { watchUploadFile } from './diverses/uploadFilesDiversesSagas';
import { wacthTransferStatus, wacthCaseUpdate, wacthInventoryHistoryStatus } from './vehicles';
import { watchSaveVideoUrl, watchGetVideoUrl, watchDeleteVideoUrl, watchGetZipPics, watchGetCaseLogs } from "./common";

export {
  watchMarketsAllowed,
  watchLogin,
  watchRequestDownloadFile,
  watchRequestFiles,
  watchUploadFile,
  wacthTransferStatus,
  wacthCaseUpdate,
  wacthInventoryHistoryStatus,
  watchSaveVideoUrl,
  watchGetVideoUrl,
  watchDeleteVideoUrl,
  watchGetZipPics,
  watchGetCaseLogs
};

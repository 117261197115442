import { takeLatest, put, call, cancelled, delay } from 'redux-saga/effects';
import {
  CASES_SEARCH_REQUEST,
  searchCasesSuccess,
  searchCasesFail,
  searchCasesCancelled,
  CASES_PRE_SELECT_REQUEST,
  casesPreSelectFail,
  casesPreSelectSuccess,
  ADVERT_DETAIL_REQUEST,
  advertDetailFail,
  advertDetailSuccess
} from 'redux/actions/advertActions';
import {
  getCasesToAdverts,
  searchPreAdvertsCases,
  engageCase,
  getAdvertById
} from 'services/adverts';
import { sendNotification } from 'helpers/helperNotifications';
import instance from 'services/request';
import { responseOK } from 'utils';
import { MSG_020 } from 'utils/messages';

export function* requestSearchAdvertsCases(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    yield delay(1000);
    const {
      firstSearch,
      wildCard,
      advertId,
      isPreAdvert,
      NoDataAction,
      hasPreAdvertCases,
      checkCases,
      caseTypeId
    } = payload;
    const response = yield call(
      isPreAdvert ? searchPreAdvertsCases : getCasesToAdverts,
      { wildCard, advertId },
      source.token
    );
    if (responseOK(response)) {
      if (response.data.data.length === 0 && firstSearch && !hasPreAdvertCases) {
        if (!isPreAdvert) sendNotification('info', MSG_020);
      }
      if (firstSearch && response.data.data.length === 0 && !isPreAdvert && !hasPreAdvertCases) {
        NoDataAction();
      }
      yield put(searchCasesSuccess(response.data.data));
      if (checkCases != undefined) {
        yield checkCases(response.data, advertId, caseTypeId);
      }
    } else {
      yield put(searchCasesFail());
    }
  } catch (e) {
    yield put(searchCasesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(searchCasesCancelled());
    }
  }
}

export function* requestEngageAdvertsCases(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    yield delay(500);
    const { data, onSuccess } = payload;

    const response = yield call(engageCase, data, source.token);
    if (responseOK(response)) {
      sendNotification('success', response.data.message);

      yield put(casesPreSelectSuccess(response.data.data));
      yield onSuccess();
    } else {
      yield put(casesPreSelectFail());
    }
  } catch (e) {
    yield put(casesPreSelectFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(casesPreSelectFail());
    }
  }
}

export function* requestAdvertDetail(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { advertId } = payload;
    const response = yield call(getAdvertById, advertId, source.token);
    if (responseOK(response)) {
      if (response.data.data.length === 0) {
        sendNotification('info', MSG_020);
      }
      yield put(advertDetailSuccess(response.data.data));
    } else {
      yield put(advertDetailFail());
    }
  } catch (e) {
    yield put(advertDetailFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchRequestSearchAdvertsCase() {
  yield takeLatest(CASES_SEARCH_REQUEST, requestSearchAdvertsCases);
}

export function* watchRequestEngageAdvertsCase() {
  yield takeLatest(CASES_PRE_SELECT_REQUEST, requestEngageAdvertsCases);
}

export function* watchRequestAdvertDetail() {
  yield takeLatest(ADVERT_DETAIL_REQUEST, requestAdvertDetail);
}

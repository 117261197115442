import { takeEvery, put, call } from 'redux-saga/effects';
import {
  TRANSFER_STATUS_REQUEST,
  transferStatusSuccess,
  transferStatusFail,
  VEHICLE_CASE_UPDATE_REQUEST,
  vehicleCaseFail,
  vehicleCaseSuccess,
  GET_INVENTORY_HISTORY_REQUEST,
  getInventoryHistorySuccess,
  getInventoryHistoryFail
} from '../../../actions/caseActions';
import { checkTransferStatus } from 'services/transfers';
import { updateVehicleCase, getCheckoutHistory } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';
import { responseOK } from 'utils';
import instance from 'services/request';

export function* caseTransferStatus({ payload }) {
  try {
    const { caseId } = payload;
    const response = yield call(checkTransferStatus, caseId);
    if (responseOK(response)) {
      yield put(transferStatusSuccess(response.data.data));
    } else {
      yield put(transferStatusFail());
    }
  } catch (_error) {
    yield put(transferStatusFail());
  }
}

export function* wacthTransferStatus() {
  yield takeEvery(TRANSFER_STATUS_REQUEST, caseTransferStatus);
}

export function* caseUpdate({ payload }) {
  try {
    const { formatData, onCaseUpdateSuccess } = payload;

    const response = yield call(updateVehicleCase, formatData);
    if (responseOK(response)) {
      yield put(vehicleCaseSuccess(response));
      yield sendNotification('success', response.data.message);
      yield onCaseUpdateSuccess();
    } else {
      yield put(vehicleCaseFail());
    }
  } catch (_error) {
    yield put(vehicleCaseFail());
  }
}

export function* wacthCaseUpdate() {
  yield takeEvery(VEHICLE_CASE_UPDATE_REQUEST, caseUpdate);
}

export function* caseInventoryHistory(payload) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getCheckoutHistory, {
      caseId: payload.payload.caseId,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(getInventoryHistorySuccess(response.data.data));
    } else {
      yield put(getInventoryHistoryFail());
    }
  } catch (_error) {
    yield put(getInventoryHistoryFail());
  }
}

export function* wacthInventoryHistoryStatus() {
  yield takeEvery(GET_INVENTORY_HISTORY_REQUEST, caseInventoryHistory);
}

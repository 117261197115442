import { takeLatest, put, call, cancelled, takeEvery } from 'redux-saga/effects';
import {
  OFFER_TYPE_REQUEST,
  ENGINE_TYPE_REQUEST,
  VEHICLES_TYPE_REQUEST,
  STATES_REQUEST,
  engineTypeSucess,
  engineTypeFail,
  vehiclesTypeSucess,
  vehiclesTypeFail,
  statesSucess,
  statesFail,
  offerTypeSucess,
  offerTypeFail,
  GET_VEHICLE_PARTS_REQUEST,
  getVehiclePartsSuccess,
  getVehiclePartsFail,
  CATALOG_DETAIL_REQUEST,
  catalogDetailSuccess,
  catalogDetailFail,
  PAYMENTS_STATUS_REQUEST,
  paymentsStatusSuccess,
  paymentsStatusFail,
  ACTION_CATALOG_REQUEST,
  actionCatalogSuccess,
  actionCatalogFail,
  DOCUMENTS_GROUP_REQUEST,
  documentsGroupSuccess,
  documentsGroupFail,
  DOCUMENT_TYPES_REQUEST,
  documentTypesSuccess,
  documentTypesFail,
  WRECK_TYPES_REQUEST,
  SUB_WRECK_TYPES_REQUEST,
  wreckTypesSuccess,
  wreckTypesFail,
  subWreckTypesSuccess,
  subWreckTypesFail,
  STATUS_TRANSFERS_REQUEST,
  statusTransfersSuccess,
  statusTransfersFail,
  DAMAGE_PARTS_REQUEST,
  damagePartsSuccess,
  damagePartsFail,
  OTHER_SUB_WRECK_TYPES_REQUEST,
  otherSubWreckTypesSuccess,
  otherSubWreckTypesFail
} from 'redux/actions/catalogsActions';
import instance from 'services/request';
import { responseOK } from 'utils';
import {
  getVehicleType,
  getEngineType,
  listStatesByCountry,
  getOfferTypes,
  getVehicleParts,
  getMarketDetail,
  getPaymentsStatus,
  addCatalog,
  updateCatalog,
  listDocumentsGroup as listDocumentsGroups,
  listDocumentsByGroup,
  getSinesterType,
  getSinesterSubtype,
  listStatusTransfer,
  getSinesterOtherSubtype
} from 'services/catalogs';

export function* requestEngineTypeTypeAsync() {
  try {
    const response = yield call(getEngineType);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(engineTypeSucess(response.data.data));
    } else {
      yield put(engineTypeFail());
    }
  } catch (error) {
    yield put(engineTypeFail());
  }
}

export function* requestVehicleTypeTypeAsync(action) {
  try {
    const { payload } = action;
    const { withAllOption } = payload;
    const response = yield call(getVehicleType);
    if (typeof response !== 'undefined' && response.status === 200) {
      if (withAllOption) {
        yield put(vehiclesTypeSucess([{ id: '*****', name: 'Todos' }, ...response.data.data]));
      } else {
        yield put(vehiclesTypeSucess(response.data.data));
      }
    } else {
      yield put(vehiclesTypeFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(vehiclesTypeFail());
  }
}

export function* requestStatesAsync(action) {
  const { payload } = action;
  const { countryId, withAllOption } = payload;
  try {
    const response = yield call(listStatesByCountry, countryId);
    if (typeof response !== 'undefined' && response.status === 200) {
      if (withAllOption) {
        yield put(statesSucess([{ id: '*****', name: 'Todos' }, ...response.data.data]));
      } else {
        yield put(statesSucess(response.data.data));
      }
    } else {
      yield put(statesFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(statesFail());
  }
}

export function* requestOfferTypeAsync(action) {
  const { payload } = action;
  const { withAllOption } = payload;
  try {
    const response = yield call(getOfferTypes);
    if (typeof response !== 'undefined' && response.status === 200) {
      if (withAllOption) {
        yield put(offerTypeSucess([...response.data.data, { id: '*****', name: 'Todos' }]));
      } else {
        yield put(offerTypeSucess(response.data.data));
      }
    } else {
      yield put(offerTypeFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(offerTypeFail());
  }
}

export function* getVehiclePartsRequestAsync() {
  try {
    const response = yield call(getVehicleParts);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(getVehiclePartsSuccess(response.data.data));
    } else {
      yield put(getVehiclePartsFail());
    }
  } catch (error) {
    console.warn('error', error);
    yield put(getVehiclePartsFail());
  }
}

export function* documentsGroupRequestWorker(action) {
  const { payload } = action;
  const { caseType } = payload;
  try {
    const response = yield call(listDocumentsGroups, caseType);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(documentsGroupSuccess(response.data.data));
    } else {
      yield put(documentsGroupFail());
    }
  } catch (error) {
    console.warn('error', error);
    yield put(documentsGroupFail());
  }
}

export function* documentTypesRequestWorker(action) {
  const { payload } = action;
  const { caseType } = payload;
  try {
    const response = yield call(listDocumentsByGroup, caseType);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(documentTypesSuccess(response.data.data));
    } else {
      yield put(documentTypesFail());
    }
  } catch (error) {
    console.warn('error', error);
    yield put(documentTypesFail());
  }
}

export function* requestCatalogDetail(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { catalogId, type, onSuccess } = payload;
    let response = false;
    if (type === 'market') {
      response = yield call(getMarketDetail, catalogId, source.token);
    }

    if (responseOK(response)) {
      yield put(catalogDetailSuccess(response.data.data));
      yield onSuccess(response.data.data);
    } else {
      yield put(catalogDetailFail());
    }
  } catch (e) {
    yield put(catalogDetailFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestPaymentsStatus() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let response = false;
    response = yield call(getPaymentsStatus, source.token);

    if (responseOK(response)) {
      yield put(paymentsStatusSuccess(response.data.data));
    } else {
      yield put(paymentsStatusFail());
    }
  } catch (e) {
    yield put(paymentsStatusFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* workWreckTypesRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let response = false;
    response = yield call(getSinesterType, source.token);

    if (responseOK(response)) {
      yield put(wreckTypesSuccess(response.data.data));
    } else {
      yield put(wreckTypesFail());
    }
  } catch (e) {
    yield put(wreckTypesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* workSubWreckTypesRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let response = false;
    response = yield call(getSinesterSubtype, source.token);

    if (responseOK(response)) {
      yield put(subWreckTypesSuccess(response.data.data));
    } else {
      yield put(subWreckTypesFail());
    }
  } catch (e) {
    yield put(subWreckTypesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestActionCatalog(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { type, data, typeService, onSuccess } = payload;
    let callService = null;
    if (type === 'update') {
      callService = updateCatalog;
    } else if (type === 'register') {
      callService = addCatalog;
    }

    const response = yield call(callService, data, typeService, source.token);
    if (responseOK(response)) {
      yield put(actionCatalogSuccess(response.data.data));
      yield onSuccess(response.data.message);
    } else {
      yield put(actionCatalogFail());
    }
  } catch (e) {
    yield put(actionCatalogFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* workStatusTransfersRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { withAllOption, filterData } = payload;
    let response = false;
    response = yield call(listStatusTransfer, { cancelToken: source.token });

    if (responseOK(response)) {
      if (filterData) {
        response.data.data = filterData(response?.data?.data);
      }

      if (withAllOption) {
        yield put(statusTransfersSuccess([...response.data.data, { id: '*****', name: 'Todos' }]));
      } else {
        yield put(statusTransfersSuccess(response.data.data));
      }
    } else {
      yield put(statusTransfersFail());
    }
  } catch (e) {
    yield put(statusTransfersFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestDamageParts(action){
  try {
    const { payload } = action;
    const { withAllOption } = payload;
    const response = yield call(getVehicleParts);
    if (typeof response !== 'undefined' && response.status === 200) {
      let objToSet = [];
      if (withAllOption) {
        yield put(damagePartsSuccess([{ id: 12, name: 'Todos' }, ...response.data.data]));
      } else {
        yield put(damagePartsSuccess(response.data.data));
      }
    } else {
      yield put(damagePartsFail());
    }
  } catch (error) {
    yield put(damagePartsFail());
  }
}

export function* workOtherSubWreckTypesRequest(action){
  try {
    const response = yield call(getSinesterOtherSubtype);
    if(responseOK(response)){
      const catalogs = response.data.data;
      yield put(otherSubWreckTypesSuccess(catalogs));
    }else{
      yield put(otherSubWreckTypesFail());
    }
  } catch (error) {
    yield put(otherSubWreckTypesFail());
  }
}

export function* watchRequestEngineType() {
  yield takeLatest(ENGINE_TYPE_REQUEST, requestEngineTypeTypeAsync);
}

export function* watchRequestOfferType() {
  yield takeLatest(OFFER_TYPE_REQUEST, requestOfferTypeAsync);
}

export function* watchVehicleTypeRequest() {
  yield takeLatest(VEHICLES_TYPE_REQUEST, requestVehicleTypeTypeAsync);
}

export function* watchStatesRequest() {
  yield takeLatest(STATES_REQUEST, requestStatesAsync);
}

export function* watchVehiclePartsRequest() {
  yield takeLatest(GET_VEHICLE_PARTS_REQUEST, getVehiclePartsRequestAsync);
}

export function* watchCatalogDetailRequest() {
  yield takeLatest(CATALOG_DETAIL_REQUEST, requestCatalogDetail);
}

export function* watchPaymentsStatus() {
  yield takeLatest(PAYMENTS_STATUS_REQUEST, requestPaymentsStatus);
}

export function* watchActionCatalog() {
  yield takeLatest(ACTION_CATALOG_REQUEST, requestActionCatalog);
}

export function* watchDocumentsGroupRequest() {
  yield takeLatest(DOCUMENTS_GROUP_REQUEST, documentsGroupRequestWorker);
}

export function* watchDocumentTypesRequest() {
  yield takeLatest(DOCUMENT_TYPES_REQUEST, documentTypesRequestWorker);
}

export function* watchWreckTypesRequest() {
  yield takeLatest(WRECK_TYPES_REQUEST, workWreckTypesRequest);
}

export function* watchSubWreckTypesRequest() {
  yield takeLatest(SUB_WRECK_TYPES_REQUEST, workSubWreckTypesRequest);
}

export function* watchStatusTransfersRequest() {
  yield takeLatest(STATUS_TRANSFERS_REQUEST, workStatusTransfersRequest);
}

export function* watchDamagePartsRequest(){
  yield takeEvery(DAMAGE_PARTS_REQUEST, requestDamageParts)
}

export function* watchOtherSubWreckTypesRequest(){
  yield takeEvery(OTHER_SUB_WRECK_TYPES_REQUEST, workOtherSubWreckTypesRequest)
}

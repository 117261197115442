import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_CLOSE_CASE, closeCaseSuccess, closeCaseFail } from 'redux/actions/caseActions';
import { deleteCase } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';

function* requestCaseCloseAsync(action) {
  const { payload } = action;
  const { caseId, onCaseClosedSuccess, onCaseClosedFail, comments } = payload;
  const data = {
    caseId: caseId,
    comment: comments
  };
  try {
    const response = yield call(deleteCase, data);
    if (typeof response !== 'undefined' && response.status === 200 && response.data.data === true) {
      yield put(closeCaseSuccess());
      yield sendNotification('success', response.data.message);
      yield onCaseClosedSuccess();
    } else {
      yield put(closeCaseFail());
      yield onCaseClosedFail();
      //yield onCaseClosedFail();
    }
  } catch (error) {
    yield put(closeCaseFail());
    //yield onCaseClosedFail();
  }
}

/* This is watching action REQUEST_CLOSE_CASE, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(REQUEST_CLOSE_CASE, requestCaseCloseAsync);
}

import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_SEARCH_ADVERTS,
  searchAdvertsSuccess,
  searchAdvertsFail,
  saveAdvertFilter
} from 'redux/actions/advertActions';
import { getAdverts } from 'services/adverts';
import { picsInventoryClean } from 'redux/actions/picsActions';

export function* requestSearchAdvertsWorker(action) {
  try {
    const { payload } = action;
    const { requestData, onSearchSuccess } = payload;
    const response = yield call(getAdverts, requestData);
    yield put(picsInventoryClean());
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(searchAdvertsSuccess(response.data.data));
      yield put(saveAdvertFilter({
        ...requestData,
        isFilter: false
      }));
      onSearchSuccess();
    } else {
      yield put(searchAdvertsFail());
    }
  } catch (e) {
    yield put(searchAdvertsFail());
  }
}

export function* watchRequestSearchAdverts() {
  yield takeLatest(REQUEST_SEARCH_ADVERTS, requestSearchAdvertsWorker);
}

import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_DOWNLOAD_DIVERSES_FILE,
  downloadDiverseFileSuccess,
  downloadDiverseFileFail
} from 'redux/actions/caseActions';
import { downloadCaseDiverseFile } from 'services/cases';

export function* requestDiverseFilesAsync(action) {
  try {
    const { payload } = action;
    const { requestData } = payload;
    const response = yield call(downloadCaseDiverseFile, requestData);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(downloadDiverseFileSuccess(response.data.data));
    } else {
      yield put(downloadDiverseFileFail());
    }
  } catch (_error) {
    yield put(downloadDiverseFileFail());
  }
}

/* This is watching action REQUEST_DOWNLOAD_DIVERSES_FILE, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestDownloadFile() {
  yield takeLatest(REQUEST_DOWNLOAD_DIVERSES_FILE, requestDiverseFilesAsync);
}

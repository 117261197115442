import { takeLatest, put, call } from 'redux-saga/effects';
import { LOCATIONS_REQUEST, locationsSuccess, locationsFail } from '../../actions/catalogs2Actions';
import { listLocationType } from 'services/catalogs';

export function* workLocationsRequest() {
  try {
    let response = yield call(listLocationType);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(locationsSuccess(response.data.data));
    } else {
      yield put(locationsFail());
    }
  } catch (error) {
    yield put(locationsFail());
  }
}

export function* watchLocationRequest() {
  yield takeLatest(LOCATIONS_REQUEST, workLocationsRequest);
}

import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import {
  AWARDINGS_BYLIST_REQUEST,
  awardingsByListSuccess,
  awardingsByListFail,
  AWARDINGS_BYADVERT_REQUEST,
  awardingsByAdvertSuccess,
  awardingsByAdvertFail,
  AWARD_BYCASE_REQUEST,
  awardByCaseSucess,
  awardByCaseFail,
  AWARD_DEADLINE_REQUEST,
  awardDeadlineSuccess,
  awardDeadlineFail,
  AWARD_DELETE_REQUEST,
  awardDeleteSuccess,
  awardDeleteFail,
  AWARD_BIDS_REQUEST,
  awardBidsSuccess,
  awardBidsFail,
  AWARDING_CASE_DETAIL_REQUEST,
  awardingCaseDetailSuccess,
  awardingCaseDetailFail,
  AWARD_REGISTER_REQUEST,
  awardRegisterSuccess,
  awardRegisterFail,
  AWARD_VALIDATE_REQUEST,
  awardValidateSuccess,
  awardValidateFail,
  setMassiveUnitaryAward,
  AWARD_HISTORY_REQUEST,
  awardHistorySuccess,
  awardHistoryFail,
  AWARD_UPDATE_REQUEST,
  awardUpdateSuccess,
  awardUpdateFail,
  setByListFilter,
  setByUnitaryFilter,
  DOWNLOAD_EXCEL_REPORT_PAYMENTS_REQUEST
} from 'redux/actions/awardingsActions';
// import { getFromAwardings } from 'redux/sagas/selectors';
import { picsInventoryClean } from 'redux/actions/picsActions';

import {
  getAwardingsByList,
  getAwardingsByAdvert,
  getAwardByCase,
  postDeadline,
  deleteAward,
  getAwardingCaseDetail,
  getBids,
  createAwarding,
  validateAwarding,
  awardHistory,
  updateAwarding,
  reportExcelPayments
} from 'services/awardings';
import { responseOK } from 'utils';
import instance from 'services/request';
import { sendNotification } from 'helpers/helperNotifications';
import i18n from 'i18next';
import { DOWNLOAD_REPORT_EXCEL_REQUEST } from 'redux/actions/advertActions';
import i18next from 'i18next';
import { casesStatusEnum } from 'models/enums';

const getFilters = (state) => state.awardings.filters

export function* requestAwardingsDeleteAsync(action) {
  try {
    const { payload } = action;
    const { requestData } = payload;
    const { advertId, caseId } = requestData;

    const response = yield call(deleteAward, advertId, caseId);
    if (responseOK(response)) {
      const actualAdvertList = yield select(state => state.awardings.unitarySearchResult.data) ?? [];
      const deletedIndex = actualAdvertList.data.findIndex(advert => advert.caseId === caseId);
      actualAdvertList.data.splice(deletedIndex, 1);
      sendNotification('success', response.data.message);
      yield put(awardDeleteSuccess([...actualAdvertList.data]));
    } else {
      yield put(awardDeleteFail());
    }
  } catch (error) {
    yield put(awardDeleteFail());
  }
}

export function* requestAwardingsDeadLineAsync(action) {
  try {
    const { payload } = action;
    const { requestData, onSuccess, paymentEndLimit, target } = payload;

    const response = yield call(postDeadline, requestData);
    if (responseOK(response)) {
      yield put(awardDeadlineSuccess(requestData));

      yield put(
        setMassiveUnitaryAward({
          evaluation: card => {
            return target.includes(card.caseId);
          },
          key: 'paymentDateLimit',
          value: paymentEndLimit
          //'6666-06-06T15:00:00'
        })
      );
      onSuccess();
      sendNotification('success', response.data.message);
    } else {
      yield put(awardDeadlineFail());
    }
  } catch (error) {
    yield put(awardDeadlineFail());
    sendNotification('error', i18n.t('MSG_002'));
  }
}

export function* requestAwardingsByListAsync(action) {
  try {
    const { payload } = action;
    const { requestData, onSearchSuccess } = payload;
    yield put(setByListFilter({
      filters: requestData,
      isFilter : true
    }));
    const response = yield call(getAwardingsByList, requestData);
    if (responseOK(response)) {
      yield put(awardingsByListSuccess(response.data));
      onSearchSuccess();
    } else {
      yield put(awardingsByListFail());
    }
    yield put(setByListFilter({
      filters: requestData,
      isFilter : false
    }));
  } catch (error) {
    yield put(awardingsByListFail());
    yield put(setByListFilter({
      filters: null,
      isFilter : false
    }));
  }
}

export function* requestAwardingsByAdvertAsync(action) {
  try {
    const { payload } = action;
    const { advertId, requestData, onSearchSuccess } = payload;
    const {byUnitary} = yield select(getFilters);
    yield put(setByUnitaryFilter({
      filters: !byUnitary.isFilter ? requestData : byUnitary.filters,
      isFilter : true,
      advertId : !byUnitary.isFilter ? advertId : byUnitary.advertId
    }));
    const response = yield call(getAwardingsByAdvert, advertId, requestData);
    yield put(picsInventoryClean());
    if (responseOK(response)) {
      yield put(awardingsByAdvertSuccess(response.data));
      onSearchSuccess();
    } else {
      yield put(awardingsByAdvertFail());
    }
    yield put(setByUnitaryFilter({
      filters: !byUnitary.isFilter ? requestData : byUnitary.filters,
      isFilter : false,
      advertId : !byUnitary.isFilter ? advertId : byUnitary.advertId
    }));
  } catch (_error) {
    yield put(awardingsByAdvertFail());
    yield put(setByUnitaryFilter({
      filters: null,
      isFilter : false,
      advertId : null
    }));
  }
}

export function* requestAwardByCase(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getAwardByCase, caseId, source.token);

    if (responseOK(response)) {
      yield put(awardByCaseSucess(response.data.data));
    } else {
      yield put(awardByCaseFail());
    }
  } catch (_e) {
    yield put(awardByCaseFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      // yield put(awardByCaseFail());
    }
  }
}

export function* requestAwardBids(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { caseId, advertId } = payload;
    // const awardings = yield select(state => state?.awardings);

    const response = yield call(getBids, advertId, caseId, source.token);
    if (responseOK(response)) {
      yield put(awardBidsSuccess(response.data.data));
    } else {
      yield put(awardBidsFail());
    }
  } catch (e) {
    yield put(awardBidsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestAwardingCaseDetail(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { caseId, onSuccess } = payload;
    const response = yield call(getAwardingCaseDetail, caseId, source.token);

    if (responseOK(response)) {
      yield put(awardingCaseDetailSuccess(response.data.data));
      yield onSuccess(response.data);
    } else {
      yield put(awardingCaseDetailFail());
    }
  } catch (_e) {
    yield put(awardingCaseDetailFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestAwardRegister(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;

    const response = yield call(createAwarding, data, source.token);
    if (responseOK(response)) {
      yield put(awardRegisterSuccess(response.data.data));
      yield onSuccess(response.data);
    } else {
      yield put(awardRegisterFail());
    }
  } catch (_e) {
    yield put(awardRegisterFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestAwardValidate(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;
    const actualList = yield select(state => state.awardings.unitarySearchResult.data) ?? [];
    const info = {
      ManagementCost: data.ManagementCost,
      PaymentEndLimit: data.PaymentEndLimit,
      Comment: data.Comment,
      awardId: data.awardId,
      Evaluation: data.Evaluation
    };
    const response = yield call(validateAwarding, info, source.token);

    if (responseOK(response)) {
      yield put(awardValidateSuccess(response.data.data));
      yield onSuccess(response.data);

      const newList = actualList.data.map(advert => {
        if (advert.caseId === data.caseId) {
          if(data.Evaluation)
            return {...advert, statusCase: 'Adjudicado', statusCaseId: casesStatusEnum.ADJUDICADO};
          else
            return {...advert, statusCase: 'Por adjudicar', statusCaseId: casesStatusEnum.POR_ADJUDICAR};
        } else {
          return advert;
        }
      });

      if (data.Evaluation) {
        yield put(
          awardDeleteSuccess(newList)
        );
      } else {
        if (data.search && data.search.caseType) {
          yield put(
            awardDeleteSuccess(newList)
          );
        } else if(!data.Evaluation) {
          yield put(
            awardDeleteSuccess(newList)
          );
        }
      }
      // if (data.search && data.search.caseType || data.evaluation) {
      //   yield put(
      //     awardDeleteSuccess({
      //       caseId: data.caseId
      //     })
      //   );
      // } else {
      //   //sí llegas por búsqueda general , puedes tener ambos estatus ,
      //   // por publicación o por unidad

      // }
    } else {
      yield put(awardValidateFail());
    }
  } catch (_e) {
    yield put(awardValidateFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestAwardHistory(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { awardId } = payload;
    const response = yield call(awardHistory, awardId, source.token);
    if (responseOK(response)) {
      yield put(awardHistorySuccess(response.data.data));
      // yield onSuccess(response.data.data.advertId);
    } else {
      yield put(awardHistoryFail());
    }
  } catch (e) {
    yield put(awardHistoryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* requestAwardUpdate(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;
    const response = yield call(updateAwarding, data, source.token);
    if (responseOK(response)) {
      yield put(awardUpdateSuccess(response.data.data));
      if (response?.data?.data?.infoAwardChanged) {
        yield onSuccess({ redirect: '/awardings/search/1', message: response.data.message });
      } else {
        yield onSuccess({ message: response.data.message });
      }
    } else {
      yield put(awardUpdateFail());
    }
  } catch (e) {
    yield put(awardUpdateFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* downloadExcelReportWorker(action) {
  try {
    const { payload } = action;
    const { dataSearch, reportType } = payload;
    const response = yield call(reportExcelPayments ,dataSearch, reportType);
    if(responseOK(response)){
      sendNotification('success',  i18next.t('on.report.queued'));
    }else{
      sendNotification('error', i18next.t('MSG_002'));
    }
  } catch (error) {
    sendNotification('error', i18next.t('MSG_002'));
  }
}

/* This is watching action , whenever is executed it starts
orchestrating the sideEffects   */
export function* watchAwardingsByListRequest() {
  yield takeLatest(AWARDINGS_BYLIST_REQUEST, requestAwardingsByListAsync);
}

export function* watchAwardingsByAdvertRequest() {
  yield takeLatest(AWARDINGS_BYADVERT_REQUEST, requestAwardingsByAdvertAsync);
}

export function* watchAwardByCase() {
  yield takeLatest(AWARD_BYCASE_REQUEST, requestAwardByCase);
}

export function* watchDeadlineRequest() {
  yield takeLatest(AWARD_DEADLINE_REQUEST, requestAwardingsDeadLineAsync);
}

export function* watchAwardingsDeleteRequest() {
  yield takeLatest(AWARD_DELETE_REQUEST, requestAwardingsDeleteAsync);
}

export function* watchAwardBids() {
  yield takeLatest(AWARD_BIDS_REQUEST, requestAwardBids);
}

export function* watchAwardingCaseDetail() {
  yield takeLatest(AWARDING_CASE_DETAIL_REQUEST, requestAwardingCaseDetail);
}

export function* watchAwardRegister() {
  yield takeLatest(AWARD_REGISTER_REQUEST, requestAwardRegister);
}

export function* watchAwardValidate() {
  yield takeLatest(AWARD_VALIDATE_REQUEST, requestAwardValidate);
}

export function* watchAwardHistory() {
  yield takeLatest(AWARD_HISTORY_REQUEST, requestAwardHistory);
}
export function* watchAwardUpdate() {
  yield takeLatest(AWARD_UPDATE_REQUEST, requestAwardUpdate);
}

export function* watchDownloadExcelReportPayments() {
  yield takeLatest(DOWNLOAD_EXCEL_REPORT_PAYMENTS_REQUEST, downloadExcelReportWorker);
}

import { takeLatest, put, call } from 'redux-saga/effects';
import {
  DELIVERY_INFO_REQUEST,
  deliveryInfoSuccess,
  deliveryInfoFail,
  REGISTER_DELIVERY_REQUEST,
  registerDeliverySuccess,
  registerDeliveryFail,
  POST_BDELIVERY_REQUEST,
  postBdeliverySuccess,
  postBdeliveryFail
} from 'redux/actions/caseActions';
import {
  DELIVERY_INFO_ADMINS_REQUEST,
  deliveryInfoAdminsSuccess,
  deliveryInfoAdminsFail,
  deliveryOrderDownloadSuccess,
  deliveryOrderDownloadFail,
  DELIVERY_ORDER_DOWNLOAD_REQUEST
} from 'redux/actions/deliveries';
import {
  getDeliveries,
  getDeliveryDetail,
  aknowledgementDelivery,
  postDelivery,
  forceDownloadFile
} from 'services/deliverys';
import { headerBase64PDF } from 'utils/constants';

import { downloadDirectory, forceDownloadFileURL } from 'services/downloadFile';
import { sendNotification } from 'helpers/helperNotifications';
import { responseOK } from 'utils';
// import { instance } from 'services/request';

export function* deliveryInfoRequestAsync(action) {
  const { payload } = action;
  const { caseId } = payload;
  try {
    const response = yield call(getDeliveries, caseId);
    if (responseOK(response)) {
      yield put(deliveryInfoSuccess(response.data.data));
    } else {
      yield put(deliveryInfoFail());
    }
  } catch (error) {
    yield put(deliveryInfoFail());
  }
}

export function* registerDeliveryRequestAsync(action) {
  const { payload } = action;
  const { data, onSuccess } = payload;
  try {
    const response = yield call(aknowledgementDelivery, data);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(registerDeliverySuccess());
      yield sendNotification('success', response.data.message);
      yield call(onSuccess);
    } else {
      yield put(registerDeliveryFail());
    }
  } catch (error) {
    yield put(registerDeliveryFail());
  }
}

export function* postBdeliveryRequestAsync(action) {
  const { payload } = action;
  const { data } = payload;
  const { GenerateAction } = data;
  try {
    let response;
    if (GenerateAction === 1) {
      response = yield call(postDelivery, data);
    } else {
      response = yield call(forceDownloadFile, data);
    }
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(postBdeliverySuccess());
      yield sendNotification('success', response.data.message);
    } else {
      yield put(postBdeliveryFail());
    }
  } catch (error) {
    yield put(postBdeliveryFail());
  }
}

export function* deliveryDetailRequest(action) {
  const { payload } = action;
  const { caseId } = payload;
  try {
    const response = yield call(getDeliveryDetail, caseId);
    if (responseOK(response)) {
      yield put(deliveryInfoAdminsSuccess(...response.data.data));
    } else {
      yield put(deliveryInfoAdminsFail());
    }
  } catch (error) {
    yield put(deliveryInfoAdminsFail());
  }
}
export function* deliveryOrderDownloadRequest(action) {
  try {
    const { payload } = action;
    const { caseId, oneFile, deliveryOrder } = payload;
    let response = false;

    if (oneFile) {
      let url = `/Files/Api/v1/Files/Download/${caseId}/${deliveryOrder}`;
      response = yield call(forceDownloadFileURL, url);
    } else {
      response = yield call(downloadDirectory, {
        caseId,
        documentTypeId: 41,
        fileName: 'Orden de entrega'
      });
    }

    if (responseOK(response)) {
      const link = document.createElement('a');
      //data:@file/zip;base64,
      const concat = `${headerBase64PDF}${response.data.data.content}`;
      link.download = response.data.data.name;
      link.href = concat;
      link.click();
      yield put(deliveryOrderDownloadSuccess(...response.data.data));
    } else {
      yield put(deliveryOrderDownloadFail());
    }
  } catch (error) {
    yield put(deliveryOrderDownloadFail());
  }
}

/* This is watching action REQUEST_DELETE_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchDeliveryInfoRequest() {
  yield takeLatest(DELIVERY_INFO_REQUEST, deliveryInfoRequestAsync);
}

export function* watchRegisterDeliveryRequestAsync() {
  yield takeLatest(REGISTER_DELIVERY_REQUEST, registerDeliveryRequestAsync);
}
export function* watchPostBdeliveryRequest() {
  yield takeLatest(POST_BDELIVERY_REQUEST, postBdeliveryRequestAsync);
}

export function* watchDeliveryDetailRequest() {
  yield takeLatest(DELIVERY_INFO_ADMINS_REQUEST, deliveryDetailRequest);
}

export function* watchDeliveryOrderDownloadRequest() {
  yield takeLatest(DELIVERY_ORDER_DOWNLOAD_REQUEST, deliveryOrderDownloadRequest);
}

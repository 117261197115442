import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import {
  REQUEST_UPLOAD_DIVERSES_FILE,
  uploadDiverseFileSuccess,
  uploadDiverseFileFail,
  diverseFilesSuccess
} from 'redux/actions/caseActions';
import { uploadFile } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';

export function* uploadDiverseFilesAsync(action) {
  try {
    const { payload } = action;
    const { requestData, onSuccess } = payload;
    const response = yield call(uploadFile, requestData);
    const existsFiles = yield select(state => state?.case?.diverseFiles?.data ?? []);
    if (typeof response !== 'undefined' && response.status === 200) {
      const newItem = {
        ...requestData,
        fileId: response.data.data,
      }
      yield put(uploadDiverseFileSuccess(response.data.data));
      yield put(diverseFilesSuccess([...existsFiles, newItem]));
      yield sendNotification('success', response.data.message);
      yield onSuccess();
    } else {
      yield put(uploadDiverseFileFail());
    }
  } catch (_error) {
    yield put(uploadDiverseFileFail());
  }
}

/* This is watching action REQUEST_UPLOAD_DIVERSES_FILE, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchUploadFile() {
  yield takeEvery(REQUEST_UPLOAD_DIVERSES_FILE, uploadDiverseFilesAsync);
}

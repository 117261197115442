import { call, put, takeEvery } from "redux-saga/effects";
import { COMPONENTS_REQUEST, componentsFloatMenuSuccess, componentsHeaderMenuSuccess } from "redux/actions/componentsActions";
import { getComponents } from "services/component";
import { responseOK } from "utils";

function* componentRequest(action) {
    try {
        const { payload } = action;
        const { componentType } = payload;
        const data = {type: componentType};
        const response = yield call(getComponents, data);
        if(responseOK(response)){
            switch (componentType) {
                case 'floatMenu':
                    yield put(componentsFloatMenuSuccess(response?.data?.data));
                    break;
                case 'headermenu':
                    yield put(componentsHeaderMenuSuccess(response?.data?.data));
                    break;
            }
        }
    } catch (error) {
        console.log(error);
    }
}

export function* watchComponentRequest() {
    yield takeEvery(COMPONENTS_REQUEST, componentRequest);
} 
import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_CASE_IMAGES, caseImagesSuccess, caseImagesFail } from 'redux/actions/caseActions';
import { getPhotosCase } from 'services/cases';

function* requestCaseImagesAsync(action) {
  const { payload } = action;
  const { caseId } = payload;
  try {
    const response = yield call(getPhotosCase, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseImagesSuccess(response.data.data));
    } else {
      yield put(caseImagesFail());
    }
  } catch (_error) {
    yield put(caseImagesFail());
  }
}

/* This is watching action REQUEST_CASE_IMAGES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(REQUEST_CASE_IMAGES, requestCaseImagesAsync);
}

import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_CASE_STATUS, caseStatusFail, caseStatusSuccess } from 'redux/actions/searchCases';
import { getCaseStatus } from 'services/catalogs';

function* requestCaseStatusAsync() {
  try {
    const response = yield call(getCaseStatus);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseStatusSuccess(response.data.data));
    } else {
      yield put(caseStatusFail());
    }
  } catch (error) {
    yield put(caseStatusFail());
    console.log('error', error);
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCaseStatus() {
  // console.log('watching watchRequestCaseType');
  yield takeLatest(REQUEST_CASE_STATUS, requestCaseStatusAsync);
}

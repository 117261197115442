import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_DEPARTMENTS,
  departmentsSuccess,
  departmentsFail
} from 'redux/actions/catalogsActions';
import { listDepartments } from 'services/catalogs';

function* requestDepartamentsAsync() {
  try {
    const response = yield call(listDepartments);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(departmentsSuccess(response.data.data));
    } else {
      yield put(departmentsFail());
    }
  } catch (_error) {
    yield put(departmentsFail());
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestDepartaments() {
  yield takeLatest(REQUEST_DEPARTMENTS, requestDepartamentsAsync);
}

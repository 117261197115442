import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_DIVERSES_FILES,
  diverseFilesSuccess,
  diverseFilesFail
} from 'redux/actions/caseActions';
import { getFilesFromDiverseCase } from 'services/cases';

export function* requestDiverseFilesAsync(action) {
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getFilesFromDiverseCase, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(diverseFilesSuccess(response.data.data));
    } else {
      yield put(diverseFilesFail());
    }
  } catch (_error) {
    yield put(diverseFilesFail());
  }
}

/* This is watching action REQUEST_DIVERSES_FILES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestFiles() {
  yield takeLatest(REQUEST_DIVERSES_FILES, requestDiverseFilesAsync);
}

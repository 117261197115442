import { watchAdverts } from './advertStatusSaga';
import {
  watchRequestSupplierByServiceType,
  watchRequestBranchesByproviderId,
  watchRequestSupplierGralByServiceType,
  watchRequestCraneBranchesByproviderId,
  watchRequestBuyersByInsuranceId,
  watchRequestIncidenceType
} from './supplierByServiceType';
import {
  watchRequestEngineType,
  watchRequestOfferType,
  watchVehicleTypeRequest,
  watchStatesRequest,
  watchVehiclePartsRequest,
  watchCatalogDetailRequest,
  watchPaymentsStatus,
  watchActionCatalog,
  watchDocumentsGroupRequest,
  watchDocumentTypesRequest,
  watchWreckTypesRequest,
  watchSubWreckTypesRequest,
  watchStatusTransfersRequest,
  watchDamagePartsRequest,
  watchOtherSubWreckTypesRequest
} from './catalogsSagas';

export {
  watchAdverts,
  watchRequestEngineType,
  watchRequestOfferType,
  watchVehicleTypeRequest,
  watchStatesRequest,
  watchVehiclePartsRequest,
  watchCatalogDetailRequest,
  watchPaymentsStatus,
  watchActionCatalog,
  watchDocumentsGroupRequest,
  watchDocumentTypesRequest,
  watchWreckTypesRequest,
  watchSubWreckTypesRequest,
  watchStatusTransfersRequest,
  watchRequestSupplierByServiceType,
  watchRequestBranchesByproviderId,
  watchRequestSupplierGralByServiceType,
  watchRequestCraneBranchesByproviderId,
  watchRequestBuyersByInsuranceId,
  watchRequestIncidenceType,
  watchDamagePartsRequest,
  watchOtherSubWreckTypesRequest
};

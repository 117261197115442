import { takeLatest, put, call, takeEvery, cancelled } from 'redux-saga/effects';
import {
  SUPPLIERS_BY_SERVICE_TYPE_REQUEST,
  supplierByServiceTypeSuccess,
  supplierByServiceTypeFail,
  BRANCHES_BY_PROVIDER_ID_REQUEST,
  branchesByProviderIdSuccess,
  branchesByProviderIdFail,
  SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST,
  supplierByServiceTypeGralSuccess,
  supplierByServiceTypeGralFail,
  BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST,
  branchesCraneByProviderIdSuccess,
  branchesCraneByProviderIdFail,
  BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST,
  buyerByInsuranceSuccess,
  buyerByInsuranceFail,
  INCIDENCE_TYPE_REQUEST,
  incidenceTypeSuccess,
  incidenceTypeFail
} from 'redux/actions/catalogsActions';
import {
  getSuppliersByServiceType,
  getBranchesByProviderIdAndServiceType
} from 'services/catalogs';
import instance from 'services/request';
import { getBuyersByInsuranceId } from 'services/buyers';
import { getIncidenceType } from 'services/incidenceType';
import { responseOK } from 'utils';

export function* requestSupplierByService({ payload }) {
  try {
    const { serviceTypeId, params, withAllOption, withOtherOption } = payload;

    const response = yield call(getSuppliersByServiceType, serviceTypeId, params);
    if (responseOK(response)) {
      if (withAllOption) {
        if (withOtherOption) {
          yield put(
            supplierByServiceTypeSuccess({
              suppliers: [
                ...response.data.data,
                { id: '*****', businessName: 'Todos' },
                { id: 'other', businessName: 'Otra ubicación' }
              ]
            })
          );
        } else {
          yield put(
            supplierByServiceTypeSuccess({
              suppliers: [{ id: '*****', businessName: 'Todos' }, ...response.data.data]
            })
          );
        }
      } else {
        yield put(supplierByServiceTypeSuccess({ suppliers: response.data.data }));
      }
    } else {
      yield put(supplierByServiceTypeFail());
    }
  } catch (_error) {
    yield put(supplierByServiceTypeFail());
  }
}

export function* requestBranchesByProviderIdWorker({ payload }) {
  try {
    const { providerId, type, withAllOption } = payload;
    const response = yield call(getBranchesByProviderIdAndServiceType, providerId, type);
    if (responseOK(response)) {
      if (withAllOption) {
        yield put(
          branchesByProviderIdSuccess({
            branches: [{ id: '*****', businessName: 'Todos' }, ...response.data.data]
          })
        );
      } else {
        yield put(branchesByProviderIdSuccess({ branches: response.data.data }));
      }
    } else {
      yield put(branchesByProviderIdFail());
    }
  } catch (_error) {
    yield put(branchesByProviderIdFail());
  }
}

export function* requestCraneBranchesByProviderIdWorker({ payload }) {
  try {
    const { providerId, type, withAllOption } = payload;
    const response = yield call(getBranchesByProviderIdAndServiceType, providerId, type);
    if (responseOK(response)) {
      if (withAllOption) {
        yield put(
          branchesCraneByProviderIdSuccess([
            { id: '*****', businessName: 'Todos' },
            ...response.data.data
          ])
        );
      } else {
        yield put(branchesCraneByProviderIdSuccess(response.data.data));
      }
    } else {
      yield put(branchesCraneByProviderIdFail());
    }
  } catch (_error) {
    yield put(branchesCraneByProviderIdFail());
  }
}

export function* requestSupplierGralByService({ payload }) {
  try {
    const { serviceTypeId, params, withAllOption } = payload;

    const response = yield call(getSuppliersByServiceType, serviceTypeId, params);
    if (responseOK(response)) {
      if (withAllOption) {
        yield put(
          supplierByServiceTypeGralSuccess({
            suppliers: [
              {
                id: '*****',
                businessName: 'Todos'
              },
              ...response.data.data
            ],
            serviceTypeId
          })
        );
      } else {
        yield put(
          supplierByServiceTypeGralSuccess({ suppliers: response.data.data, serviceTypeId })
        );
      }
    } else {
      yield put(supplierByServiceTypeGralFail());
    }
  } catch (_error) {
    yield put(supplierByServiceTypeGralFail());
  }
}

export function* requestBuyersByInsuranceIdWorker({ payload }) {
  try {
    const { insuranceCarrierId } = payload;
    const response = yield call(getBuyersByInsuranceId, insuranceCarrierId);
    if (responseOK(response)) {
      yield put(
        buyerByInsuranceSuccess([...response.data.data, { userId: 'other', buyerName: 'Otro' }])
      );
    } else {
      yield put(buyerByInsuranceFail('FAIL'));
    }
  } catch (_error) {
    yield put(buyerByInsuranceFail('FAIL'));
  }
}

export function* requestIncidenceTypeWorker() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getIncidenceType, { cancelToken: source.token });
    if (responseOK(response)) {
      yield put(incidenceTypeSuccess(response.data.data));
    } else {
      yield put(incidenceTypeFail());
    }
  } catch (_error) {
    yield put(incidenceTypeFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchRequestSupplierByServiceType() {
  yield takeEvery(SUPPLIERS_BY_SERVICE_TYPE_REQUEST, requestSupplierByService);
}

export function* watchRequestBranchesByproviderId() {
  yield takeLatest(BRANCHES_BY_PROVIDER_ID_REQUEST, requestBranchesByProviderIdWorker);
}

export function* watchRequestSupplierGralByServiceType() {
  yield takeEvery(SUPPLIERS_BY_SERVICE_TYPE_GRAL_REQUEST, requestSupplierGralByService);
}

export function* watchRequestCraneBranchesByproviderId() {
  yield takeLatest(BRANCHES_CRANE_BY_PROVIDER_ID_REQUEST, requestCraneBranchesByProviderIdWorker);
}

export function* watchRequestBuyersByInsuranceId() {
  yield takeLatest(BUYERS_BY_INSURANCE_CARRIER_ID_REQUEST, requestBuyersByInsuranceIdWorker);
}

export function* watchRequestIncidenceType() {
  yield takeLatest(INCIDENCE_TYPE_REQUEST, requestIncidenceTypeWorker);
}

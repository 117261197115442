import { takeLatest, put, call, select } from 'redux-saga/effects';
import { REQUEST_COUNTRIES, countriesSuccess, countriesFail } from 'redux/actions/catalogsActions';
import { listCountries } from 'services/catalogs';
import { getCatalogFromState } from './selectors';

function* requestCountiresAsync() {
  const countries = yield select(state => getCatalogFromState(state, 'countries'));

  if (typeof countries !== 'undefined' && countries.length > 0) {
    return countries;
  }

  try {
    const response = yield call(listCountries);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(countriesSuccess(response.data.data));
    } else {
      yield put(countriesFail());
    }
  } catch (error) {
    yield put(countriesFail());
    console.log('error', error);
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(REQUEST_COUNTRIES, requestCountiresAsync);
}

import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  updateDocuments,
  TOGGLE_SLIDE,
  DELETE_FROM_FILE,
  ADD_FILE,
  ADD_DOCUMENT
} from 'redux/actions/attachmentsActions';
import { getFromStore } from 'redux/sagas/selectors';

export const helperMerge = (data, key, value, element, option) => {
  const exclude = data.filter(item => item[key] !== value);
  const found = data.filter(item => item[key] === value);
  let merge = [];
  if (option === 'deleteOption') {
    let currentFiles = found[0].files;

    currentFiles = currentFiles > 0 ? currentFiles - 1 : 0;
    if (currentFiles === 0) {
      merge = [
        ...exclude,
        { ...found[0], files: currentFiles, fileLoad: false, switchState: false }
      ];
    } else {
      merge = [...exclude, { ...found[0], files: currentFiles }];
    }
  } else if (option === 'addOption') {
    let currentFiles = found[0].files;
    currentFiles = currentFiles + 1;
    merge = [...exclude, { ...found[0], files: currentFiles, fileLoad: true, switchState: true }];
  } else if (option === 'addDocument') {
    if (found.length > 0) {
      merge = data;
    } else {
      merge = [...exclude, element];
    }
  } else {
    merge = [...exclude, element];
  }

  return merge;
  //return withoutFind.sort(orderArrayObjects('fileId'));
};

export function* toggleSlideWorker(action) {
  const { payload } = action;
  const data = yield select(state => getFromStore(state, 'attachments', 'documents'));
  let doc = data.filter(item => item['documentTypeId'] === payload.documentTypeId);
  const result = yield call(helperMerge, data, 'documentTypeId', payload.documentTypeId, {
    ...doc[0],
    switchState: !payload.switchState,
    fileLoad: !payload.switchState
  });
  yield put(updateDocuments(result));
}

export function* addFileWorker(action) {
  const { payload } = action;
  const { document } = payload;

  const data = yield select(state => getFromStore(state, 'attachments', 'documents'));
  const result = yield call(
    helperMerge,
    data,
    'documentTypeId',
    document.documentTypeId,
    {},
    'addOption'
  );
  yield put(updateDocuments(result));
}

export function* addDocumentWorker(action) {
  const { payload } = action;
  const { document } = payload;

  const data = yield select(state => getFromStore(state, 'attachments', 'documents'));
  const result = yield call(
    helperMerge,
    data,
    'documentTypeId',
    document.documentTypeId,
    document,
    'addDocument'
  );
  yield put(updateDocuments(result));
}

export function* deleteFileWorker(action) {
  const { payload } = action;
  const data = yield select(state => getFromStore(state, 'attachments', 'documents'));
  const result = yield call(
    helperMerge,
    data,
    'documentTypeId',
    payload.documentTypeId,
    {},
    'deleteOption'
  );
  yield put(updateDocuments(result));
}
export function* watchToggle() {
  yield takeLatest(TOGGLE_SLIDE, toggleSlideWorker);
}

export function* watchAddFile() {
  yield takeLatest(ADD_FILE, addFileWorker);
}

export function* watchAddDocument() {
  yield takeLatest(ADD_DOCUMENT, addDocumentWorker);
}

export function* watchDeleteFile() {
  yield takeLatest(DELETE_FROM_FILE, deleteFileWorker);
}

import {
  BUYER_ADVERT_DETAIL_REQUEST,
  advertDetailSuccess,
  advertDetailFail,
  BID_ADVERT_REQUEST,
  FAV_ADVERT_REQUEST,
  REFRESH_ADVERT_LIST_REQUEST,
  SEARCH_ADVERTS_4_BUYERS_REQUEST,
  bidAdvertFail,
  bidAdvertSuccess,
  favAdvertFail,
  favAdvertSuccess,
  searchAdverts4BuyersFail,
  searchAdverts4BuyersSuccess,
  AWARDINGS_SEARCH_REQUEST,
  awardingsSearchSuccess,
  awardingsSearchFail,
  AWARDINGS_DETAIL_REQUEST,
  awardingsDetailSuccess,
  awardingsDetailFail,
  BUYER_INVITATION_REQUEST,
  buyerInvitationSuccess,
  buyerInvitationFail,
  GET_VOUCHERS_REQUEST,
  getVouchersFail,
  getVouchersSuccess,
  DELETE_VOUCHER_REQUEST,
  deleteVoucherSuccess,
  deleteVoucherFail,
  ADD_VOUCHER_REQUEST,
  addVoucherSuccess,
  addVoucherFail,
  getVouchersRequest,
  VIEW_VOUCHER_REQUEST,
  viewVoucherSuccess,
  viewVoucherFail,
  VALIDATE_PAYMENT_REQUEST,
  validatePaymentSuccess,
  validatePaymentFail,
  SHOW_REJECT_COMMENTS_REQUEST,
  showRejectCommentsSuccess,
  showRejectCommentsFail,
  awardingsDetailRequest,
  getDeliveryRequest as getDeliveryReq,
  GET_DELIVERY_REQUEST,
  getDeliverySuccess,
  getDeliveryFail,
  postDeliveryCommentSuccess,
  postDeliveryCommentFail,
  POST_DELIVERY_COMMENT_REQUEST,
  GET_ADVERT_IMAGES,
  getAdvertImages,
  setAdvertImages,
  GET_ADVERT_IMAGES_FULL_SCREEN,
  GET_ADVERT_COMMENTS_REQUEST,
  getAdvertCommentsFail,
  getAdvertCommentsSuccess
} from 'redux/actions/buyersActions';
import { paymentDetailRequest } from 'redux/actions/paymentsActions';
import { takeLatest, put, call, cancelled, select, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  getAdverts,
  markFav,
  bidAdvert,
  getAdvertBuyerDetail,
  getAwardingsList,
  getAwardingDetail,
  uploadVoucher,
  deleteVoucher,
  getReferenceData,
  validateFile,
  getRejectComments,
  putVoucher,
  getThumbnailList
} from 'services/buyers';
import { getDeliveryDetailBuyers, postDeliveryComment } from 'services/deliverys';
import { getDownloadFileParams } from 'services/files';
import { sendSingleInvitation } from 'services/users';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getFromStore } from '../selectors';
import { sendNotification } from 'helpers/helperNotifications';
import i18next from 'i18next';
import { getCommentsDamages } from 'services/damages';

//separar paginass
export function mergeData(currentResults, response, page) {
  const { data } = currentResults;
  let pages = [];
  let currentPage;
  //data && data.length !== 0 ? { ...data.pages } : { ...pages }
  let pageFrom = {};
  if (typeof page !== 'undefined' && data && data.length !== 0) {
    pageFrom = { ...data.pages };
    currentPage = page;
  } else {
    currentPage = 1;
    pageFrom = { ...pages };
  }

  if(response.items.length === 0)
    pages = {page1: []};
  else
    pages = { ...pageFrom, [`page${currentPage}`]: response.items };

  return { pages, totalPage: response.totalPage,totalItems: response.totalItems};
}

export function* searchAdverts4BuyersAsync(action) {
  const currentResults = yield select(state => getFromStore(state, 'buyers', 'searchResults'));
  try {
    const { payload } = action;
    const { requestData, onSearchSuccess,pageLength } = payload;
    const response = yield call(getAdverts, { ...requestData, pageLength: pageLength?pageLength:5});
    if (typeof response !== 'undefined' && response.status === 200) {
      const stateUpdated = yield mergeData(currentResults, response.data.data, requestData.page);
      yield put(searchAdverts4BuyersSuccess({ data: stateUpdated, querySearch: requestData }));
      const cpItems = response?.data?.data?.items;
      yield onSearchSuccess();
      for(const item of cpItems){
        let advertObj = {
          advertId: item.advertId,
          caseId: item.caseId,
        };
        yield put (getAdvertImages(advertObj));
      };
    } else {
      yield put(searchAdverts4BuyersFail());
    }
  } catch (error) {
    yield put(searchAdverts4BuyersFail());
  }
}

function* getAdvertImagesSaga(action){
  const { payload } = action;
  const advertImagesCurrent = yield select(state => state?.buyers?.images?.adverts);
  try {
    const finalCasePics = [];
      const alreadyExist = advertImagesCurrent?.hasOwnProperty(payload.advertId) && advertImagesCurrent[payload.advertId]?.some((item) => item.caseId === payload.caseId);
      if(!alreadyExist){
        const responseThumbnail = yield call(getThumbnailList, payload.advertId, payload.caseId, true);
        if(responseOK(responseThumbnail)){
          const imageList = responseThumbnail.data.data;
          imageList.forEach(image => {
            const objToSave = {
              caseId: payload.caseId,
              blob:{
                thumbnail: image.content,
                fullScreen: null
              },
              id: image.fileId,
              name: image.name
            }
            finalCasePics.push(objToSave);
          });
        }
      }      
    advertImagesCurrent[payload.advertId] = [...advertImagesCurrent[payload.advertId] ?? [], ...finalCasePics];
    yield put(setAdvertImages(advertImagesCurrent));
  } catch (error) {
    console.log('error', error);
  }
};

function* getAdvertImagesFullScreenSaga(action){
  const { payload } = action;
  const {imagesWithoutFullScreen: pics, advertId, caseId} = payload;
  const advertImagesCurrent = yield select(state => state?.buyers?.images?.adverts);
  try {
    const finalCasePics = [];
    if(pics.length === 0) return;
    const response = yield call(getThumbnailList, advertId, caseId, false);
    if(responseOK(response)){
      for(const pic of pics){
          const responseFullScreen = response.data.data.find((item) => item.fileId === pic.id).content;
          const objToSave = {
            caseId: caseId,
            fullScreen: responseFullScreen,
            id: pic.id
          }
          finalCasePics.push(objToSave);
      }
      const newImages = advertImagesCurrent[advertId].map((item) => {
        const newPic = finalCasePics.find((pic) => pic.id === item.id);
        if(newPic){
          return {
            ...item,
            blob: {
              ...item.blob,
              fullScreen: newPic.fullScreen
            }
          }
        }
        return item;
      });
      advertImagesCurrent[advertId] = newImages;
      yield put(setAdvertImages(advertImagesCurrent));
    }
  } catch (error) {
    console.log('error', error);
  }
};

export function* favAdvertAsync(action) {
  try {
    const { payload } = action;
    const { requestData, onSuccessFavorite } = payload;
    const response = yield call(markFav, requestData);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield call(onSuccessFavorite);
      yield put(favAdvertSuccess());
    } else {
      yield put(favAdvertFail());
    }
  } catch (error) {
    yield put(favAdvertFail());
  }
}

export function* bidAdvertRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let topOffers = false;
  try {
    const { payload } = action;
    const { params, onSuccess, onFail } = payload;

    const response = yield call(bidAdvert, params, source.token);
    if (responseOK(response)) {
      yield put(bidAdvertSuccess(response.data.data));
      topOffers = response.data.data;
      yield onSuccess(response.data.message, topOffers, params);
    } else {
      yield onFail(response.data.message);
      yield put(bidAdvertFail());
    }
  } catch (e) {
    yield put(bidAdvertFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* advertDetailRequestAsync(action) {
  try {
    const { payload } = action;
    const { advertId, caseId, visits } = payload;
    const response = yield call(getAdvertBuyerDetail, advertId, caseId, visits);
    if (typeof response !== 'undefined' && response.status === 200) {
      /**
       *  Note that this is putting the response at the same level
       *  as is much easier to handle null
       *  */

      const formatedData = {
        ...response.data.data,
        caseInfo: {}
      };
      const dataToSave = {
        ...formatedData,
        ...response.data.data.caseInfo
      };

      yield put(advertDetailSuccess(dataToSave));
    } else {
      yield put(advertDetailFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(advertDetailFail());
  }
}

export function* awardingsSearchReqAsync(action) {
  try {    
    const { payload } = action;
    const { requestData, onSearchSuccess } = payload;
    const response = yield call(getAwardingsList, requestData);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield call(onSearchSuccess);
      requestData.isFilter = false;
      yield put(awardingsSearchSuccess({ result: response.data.data, requestData,totalPages:response.data.totalPages }));
    } else {
      yield put(awardingsSearchFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(awardingsSearchFail());
  }
}

export function* awardingDetailReqlAsync(action) {
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getAwardingDetail, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(awardingsDetailSuccess(response.data.data));      
      if (response.data.data.sendValidate) sendNotification('info', i18next.t('MSG_141'));
    } else {
      yield put(awardingsDetailFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(awardingsDetailFail());
  }
}

export function* buyerInvitationRequestAsync(action) {
  try {
    const { payload } = action;
    const { messageData, onInvitationSucess } = payload;
    const response = yield call(sendSingleInvitation, messageData);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(buyerInvitationSuccess(response.data.data));
      sendNotification('success', response.data.message);
      onInvitationSucess();
    } else {
      yield put(buyerInvitationFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(buyerInvitationFail());
  }
}

export function* getVouchersRequestAsync(action) {
  try {
    const { payload } = action;
    const { referenceId } = payload;
    const response = yield call(getReferenceData, referenceId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(getVouchersSuccess(response.data.data.files));
    } else {
      yield put(getVouchersFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(getVouchersFail());
  }
}

export function* deleteVoucherRequestAsync(action) {
  try {
    const { payload } = action;
    const { requestData } = payload;
    const { paymentReferenceId, fileId, referenceId } = requestData;
    const response = yield call(deleteVoucher, paymentReferenceId, fileId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(deleteVoucherSuccess({ fileId: action.payload.requestData.fileId }));
      sendNotification('success', response.data.message);
      const data = { referenceId: referenceId };
      yield put(getVouchersRequest(data));
    } else {
      yield put(deleteVoucherFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(deleteVoucherFail());
  }
}

export function* addVoucherRequestAsync(action) {
  try {
    const { payload } = action;
    const { requestData, type } = payload;
    let response;

    if (type === 'POST') {
      response = yield call(uploadVoucher, requestData);
    } else if (type === 'PUT') {
      response = yield call(putVoucher, requestData);
    }

    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(addVoucherSuccess());
      const existVouchers = yield select(state => state.buyers.vouchers.data) ?? [];
      if(type === 'POST'){
        let objToPush = {
          ...requestData,
          fileId: response?.data?.data ?? '',
          name: requestData.fileName,
          content: null
        }
        yield put(getVouchersSuccess([...existVouchers, objToPush]));
      }else if(type === 'PUT'){
        let objToPush = {
          ...requestData,
          fileId: requestData.fileId,
          name: requestData.fileName,
          content: null
        }
        const newVouchers = existVouchers.map((item) => {
          if(item.fileId === objToPush.fileId){
            return objToPush;
          }else{
            return item;
          }
        });
        yield put(getVouchersSuccess(newVouchers));
      }
      sendNotification('success', response.data.message);
    } else {
      yield put(addVoucherFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(addVoucherFail());
  }
}

export function* viewVoucherRequestAsync(action) {
  try {
    const { payload } = action;
    const { awardId, fileId } = payload;
    const urlParams = `${awardId}/${fileId}`;
    const response = yield call(getDownloadFileParams, urlParams);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(viewVoucherSuccess(response.data.data));
    } else {
      yield put(viewVoucherFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(viewVoucherFail());
  }
}

export function* validatePaymentRequestAsync(action) {
  try {
    const { payload } = action;
    const { awardId, caseId, fromAdmin } = payload;
    const response = yield call(validateFile, awardId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(validatePaymentSuccess(response.data.data));
      yield sendNotification('success', response.data.message);
      
      if (fromAdmin === undefined) {
        yield put(awardingsDetailRequest({ caseId: caseId }));
      }
      else {
        yield put(paymentDetailRequest({ awardId: awardId }));
      }

    } else {
      yield put(validatePaymentFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(validatePaymentFail());
  }
}

export function* showRejectCommentsRequestAsync(action) {
  try {
    const { payload } = action;
    const { awardId, fileId } = payload;
    const response = yield call(getRejectComments, awardId, fileId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(showRejectCommentsSuccess(response.data.data));
    } else {
      yield put(showRejectCommentsFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(showRejectCommentsFail());
  }
}

export function* getDeliveryRequestAsync(action) {
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getDeliveryDetailBuyers, caseId);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(getDeliverySuccess(response.data.data));
    } else {
      yield put(getDeliveryFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(getDeliveryFail());
  }
}

export function* workPostDeliveryCommentRequest(action) {
  try {
    const { payload } = action;
    const { data, onSuccess, caseId } = payload;
    const response = yield call(postDeliveryComment, data);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(postDeliveryCommentSuccess(response.data.data));

      yield sendNotification('success', response.data.message);
      yield call(onSuccess);
      yield put(getDeliveryReq({ caseId }));
    } else {
      yield put(postDeliveryCommentFail());
    }
  } catch (error) {
    console.error('error', error);
    yield put(postDeliveryCommentFail());
  }
}

export function* getAdvertCommentsSaga(action){
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getCommentsDamages, caseId);
    if(responseOK(response)){
      const data = response.data.data;
      yield put(getAdvertCommentsSuccess(data))
    }else{
      yield put(getAdvertCommentsFail());
    }
  } catch (error) {
    console.log(error);
    yield put(getAdvertCommentsFail());
  }
};

export function* watchSearchBuyersRequest() {
  yield takeLatest(SEARCH_ADVERTS_4_BUYERS_REQUEST, searchAdverts4BuyersAsync);
}

export function* watchRefreshAdvertsRequest() {
  yield takeLatest(REFRESH_ADVERT_LIST_REQUEST, searchAdverts4BuyersAsync);
}

export function* watchFavAdvertRequest() {
  yield takeLatest(FAV_ADVERT_REQUEST, favAdvertAsync);
}

export function* watchBidAdvertRequest() {
  yield takeLatest(BID_ADVERT_REQUEST, bidAdvertRequest);
}

export function* watchAdvertDetailRequest() {
  yield takeLatest(BUYER_ADVERT_DETAIL_REQUEST, advertDetailRequestAsync);
}

export function* watchAwardingsSearchReq() {
  yield takeLatest(AWARDINGS_SEARCH_REQUEST, awardingsSearchReqAsync);
}

export function* watchAwardingsDetailReq() {
  yield takeLeading(AWARDINGS_DETAIL_REQUEST, awardingDetailReqlAsync);
}

export function* watchBuyerInvitationRequest() {
  yield takeLatest(BUYER_INVITATION_REQUEST, buyerInvitationRequestAsync);
}

export function* watchGetVouchersRequest() {
  yield takeLatest(GET_VOUCHERS_REQUEST, getVouchersRequestAsync);
}
export function* watcDeleteVoucherRequest() {
  yield takeLatest(DELETE_VOUCHER_REQUEST, deleteVoucherRequestAsync);
}

export function* watchAddVoucherRequest() {
  yield takeEvery(ADD_VOUCHER_REQUEST, addVoucherRequestAsync);
}

export function* watchviewVoucherRequest() {
  yield takeLatest(VIEW_VOUCHER_REQUEST, viewVoucherRequestAsync);
}

export function* watchValidatePaymentRequest() {
  yield takeLatest(VALIDATE_PAYMENT_REQUEST, validatePaymentRequestAsync);
}

export function* watchShowRejectCommentsRequest() {
  yield takeLatest(SHOW_REJECT_COMMENTS_REQUEST, showRejectCommentsRequestAsync);
}

export function* getDeliveryRequest() {
  yield takeLeading(GET_DELIVERY_REQUEST, getDeliveryRequestAsync);
}

export function* watchPostDeliveryCommentRequest() {
  yield takeLeading(POST_DELIVERY_COMMENT_REQUEST, workPostDeliveryCommentRequest);
}

export function* watchGetAdvertImages() {
  yield takeEvery(GET_ADVERT_IMAGES, getAdvertImagesSaga);
}

export function* watchGetAdvertImagesFullScreen(){
  yield takeEvery(GET_ADVERT_IMAGES_FULL_SCREEN, getAdvertImagesFullScreenSaga);
}

export function* watchGetAdvertComments(){
  yield takeEvery(GET_ADVERT_COMMENTS_REQUEST, getAdvertCommentsSaga);
}
import { takeEvery, put, call } from 'redux-saga/effects';
import { responseOK } from 'utils';

import { getCommentsDamages } from 'services/damages';
import { REQUEST_DAMAGE_COMMENTS, damageCommentsFail, damageCommentsSuccess } from 'redux/actions/caseDamageActions';

export function* getDamageCommentsWorker({payload}){
  try {
    const { caseId } = payload;
    const response = yield call(getCommentsDamages, caseId);
    if(responseOK(response)){
      const {data} = response.data;
      yield put(damageCommentsSuccess(data));
    }else{
      yield put(damageCommentsFail());
    }
  } catch (error) {
    console.log(error);
    yield put(damageCommentsFail());
  }
}

export function* watchDamageComments(){
  yield takeEvery(REQUEST_DAMAGE_COMMENTS, getDamageCommentsWorker);
}

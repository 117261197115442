import { takeLatest, put, call } from 'redux-saga/effects';
import { REQUEST_CASE_TYPE, caseTypeFail, caseTypeSuccess } from 'redux/actions/searchCases';
import { caseType } from 'services/catalogs';

function* requestCaseTypesAsync() {
  try {
    const response = yield call(caseType);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(caseTypeSuccess(response.data.data));
    } else {
      yield put(caseTypeFail());
    }
  } catch (_error) {
    yield put(caseTypeFail());
  }
}

/* This is watching action REQUEST_CASE_TYPE, whenever is executed it starts
orchestrating the sideEffects by calling another function   */
export function* watchRequestCaseTypes() {
  yield takeLatest(REQUEST_CASE_TYPE, requestCaseTypesAsync);
}

import { call, put, takeLatest } from "redux-saga/effects";
import { REQUEST_CANCEL_REASONS, cancelReasonsFail, cancelReasonsSuccess } from "redux/actions/catalogsActions";
import { listCancelReasons } from "services/catalogs";
import { responseOK } from "utils";

function* requestCancelReasonsAsync(action){
    const {payload} = action;
    const language = payload.language ?? 'en';

    try {
        const response = yield call(listCancelReasons, language);
        if(responseOK(response)){
            yield put(cancelReasonsSuccess(response.data.data));
        }else{
            yield put(cancelReasonsFail());
        }
    } catch (error) {
        yield put(cancelReasonsFail());
    }
};

export function* watchRequestCancelReasons(){
    yield takeLatest(REQUEST_CANCEL_REASONS, requestCancelReasonsAsync);
}
import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import {
  POST_ADVERT_REQUEST,
  postAdvertSuccess,
  postAdvertFail,
  ACTIVE_ADVERT_REQUEST,
  activeAdvertFail,
  activeAdvertSuccess,
  REMOVE_CASE_FROM_ADVERT_REQUEST,
  removeCaseFromAdvertSuccess,
  removeCaseFromAdvertFail,
  HISTORY_ADVERT_REQUEST,
  historyAdvertFail,
  historyAdvertSuccess,
  NOTIFY_BUYERS_REQUEST,
  notifyBuyersSucess,
  notifyBuyersFail,
  UPDATE_CASE_COSTS_REQUEST,
  updateCaseCostsSuccess,
  updateCaseCostsFail,
  CHANGE_ADVERT_STATUS_REQUEST,
  changeAdvertStatusSuccess,
  changeAdvertStatusFail,
  ADVERT_RESULT_REQUEST,
  advertResultSuccess,
  advertResultFail
} from 'redux/actions/advertActions';
import {
  CHECK_CASE_TO_ADVERT_REQUEST,
  checkCaseToAdvertFail,
  checkCaseToAdvertSuccess
} from 'redux/actions/caseActions';
import instance from 'services/request';
import {
  postAdvert,
  activateAdvert,
  updateAdvert,
  checkCaseToAdvert,
  disengageCase,
  getAdvertByCase,
  notifyBuyers,
  updateCaseCosts,
  changeStatusAdvert,
  getAdvertsResults
} from 'services/adverts';
import { responseOK } from 'utils';
import { sendNotification } from 'helpers/helperNotifications';

export function* requestPostAdvertAsync(action) {
  try {
    const { payload } = action;
    const { requestData, onPostSuccess, additionalDataToSave, type, advertId } = payload;
    const { caseTypeId } = requestData;
    let response = null;
    if (type === 'register') {
      response = yield call(postAdvert, requestData);
    } else {
      response = yield call(updateAdvert, advertId, requestData);
    }
    if (responseOK(response)) {
      const dataToSave = {
        advertId: response.data.data,
        isPosting: false,
        ...additionalDataToSave
      };
      yield put(postAdvertSuccess(dataToSave));
      yield onPostSuccess(caseTypeId, response.data.data, response.data.message);
    } else {
      yield put(postAdvertFail());
    }
  } catch (error) {
    yield put(postAdvertFail());
  }
}

export function* requestAdvertActivate(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess, onFail } = payload;
    const response = yield call(activateAdvert, data, source.token);
    //const response = { status: 200, data: { message: 'success' } };
    // yield delay(2000);
    if (responseOK(response)) {
      // if (response.data.data.length === 0) {
      sendNotification('success', response.data.message);
      //}
      yield put(activeAdvertSuccess(response.data.data));
      yield onSuccess();
    } else {
      if(onFail)
        yield onFail();
      yield put(activeAdvertFail());
    }
  } catch (e) {
    yield put(activeAdvertFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* requestCheckCaseAdvert(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess } = payload;

    const response = yield call(checkCaseToAdvert, payload, source.token);
    if (responseOK(response)) {
      sendNotification('success', response.data.message);
      yield put(checkCaseToAdvertSuccess(response.data.data));
      yield onSuccess();
    } else {
      yield put(checkCaseToAdvertFail());
    }
  } catch (e) {
    yield put(checkCaseToAdvertFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* requestDisEngageAdvertsCases(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;

    const response = yield call(disengageCase, data, source.token);
    if (responseOK(response)) {
      sendNotification('success', response.data.message);

      yield put(removeCaseFromAdvertSuccess());
      yield onSuccess();
    } else {
      yield put(removeCaseFromAdvertFail());
    }
  } catch (e) {
    yield put(removeCaseFromAdvertFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      yield put(removeCaseFromAdvertFail());
    }
  }
}

export function* requestAdvertHistory(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { caseId } = payload;
    const response = yield call(getAdvertByCase, caseId, source.token);
    //const response = { status: 200, data: { message: 'success' } };
    // yield delay(2000);
    if (responseOK(response)) {
      // if (response.data.data.length === 0) {
      // sendNotification('success', response.data.message);
      //}
      yield put(historyAdvertSuccess(response.data.data));
    } else {
      yield put(historyAdvertFail());
    }
  } catch (e) {
    yield put(historyAdvertFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
      // yield put(activeAdvertFail());
    }
  }
}

export function* notifyBuyersAsync(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data, onSuccess } = payload;
    const response = yield call(notifyBuyers, data, source.token);

    if (responseOK(response)) {
      sendNotification('success', response.data.message);

      yield put(notifyBuyersSucess(response.data.data));
      yield onSuccess();
    } else {
      yield put(notifyBuyersFail());
    }
  } catch (e) {
    yield put(notifyBuyersFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* requestUpdateCaseCosts(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const response = yield call(updateCaseCosts, payload, source.token);

    if (responseOK(response)) {
      yield put(updateCaseCostsSuccess(response.data.data));
    } else {
      yield put(updateCaseCostsFail());
    }
  } catch (e) {
    yield put(updateCaseCostsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* requestChangeStatusAdvert(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { advertId, onSuccess } = payload;

    const response = yield call(changeStatusAdvert, advertId, source.token);

    if (responseOK(response)) {
      yield put(changeAdvertStatusSuccess(response.data.data));
      sendNotification('success', response.data.message);

      yield onSuccess();
    } else {
      yield put(changeAdvertStatusFail());
    }
  } catch (e) {
    yield put(changeAdvertStatusFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* requestAdvertResultsAsync(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { advertId, caseId } = payload;

    const response = yield call(getAdvertsResults, advertId, caseId, source.token);

    if (responseOK(response)) {
      yield put(advertResultSuccess(response.data.data));
    } else {
      yield put(advertResultFail());
    }
  } catch (e) {
    yield put(advertResultFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

/* This is watching action , whenever is executed it starts
orchestrating the sideEffects   */
export function* watchPostAdvert() {
  yield takeLatest(POST_ADVERT_REQUEST, requestPostAdvertAsync);
}

export function* watchAdvertActivate() {
  yield takeLatest(ACTIVE_ADVERT_REQUEST, requestAdvertActivate);
}

export function* watchCheckCaseAdvert() {
  yield takeLatest(CHECK_CASE_TO_ADVERT_REQUEST, requestCheckCaseAdvert);
}

export function* watchRemoveCaseFromAdvert() {
  yield takeLatest(REMOVE_CASE_FROM_ADVERT_REQUEST, requestDisEngageAdvertsCases);
}

export function* watchHistoryAdvert() {
  yield takeLatest(HISTORY_ADVERT_REQUEST, requestAdvertHistory);
}

export function* watchNotifyBuyersRequest() {
  yield takeLatest(NOTIFY_BUYERS_REQUEST, notifyBuyersAsync);
}

export function* watchUpdateCaseCosts() {
  yield takeLatest(UPDATE_CASE_COSTS_REQUEST, requestUpdateCaseCosts);
}

export function* watchChangeStatusAdvert() {
  yield takeLatest(CHANGE_ADVERT_STATUS_REQUEST, requestChangeStatusAdvert);
}

export function* watchaAdvertResultsRequest() {
  yield takeLatest(ADVERT_RESULT_REQUEST, requestAdvertResultsAsync);
}

import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_ADVERT_STATUS,
  advertStatusSuccess,
  advertStatusFail
} from 'redux/actions/searchAdverts';
import { getAdvertsTypes } from 'services/catalogs';

export function* requestAdvertsAsync() {
  try {
    const response = yield call(getAdvertsTypes);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(advertStatusSuccess(response.data.data));
    } else {
      yield put(advertStatusFail());
    }
  } catch (_error) {
    yield put(advertStatusFail());
  }
}

/* This is watching action REQUEST_COUNTRIES, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchAdverts() {
  yield takeLatest(REQUEST_ADVERT_STATUS, requestAdvertsAsync);
}

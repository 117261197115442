import { takeLatest, put, call } from 'redux-saga/effects';
import {
  REQUEST_DELETE_CASE_PHOTO,
  deleteCaseImagesSuccess,
  deleteCaseImagesFail,
  deletePhotoCase
} from 'redux/actions/caseActions';
import { deleteCasePic } from 'services/cases';
import { sendNotification } from 'helpers/helperNotifications';

function* deleteCasePicAsync(action) {
  const { payload } = action;
  const { fileId, caseId } = payload;

  try {
    const response = yield call(deleteCasePic, { caseId: caseId, fileId: fileId });
    if (typeof response !== 'undefined' && response.status === 200) {
      yield sendNotification('success', response.data.message);
      yield put(deletePhotoCase(fileId));
      yield put(deleteCaseImagesSuccess());
    } else {
      yield put(deleteCaseImagesFail());
    }
  } catch (_error) {
    yield put(deleteCaseImagesFail());
  }
}

/* This is watching action REQUEST_DELETE_CASE_PHOTO, whenever is executed it starts
orchestrating the sideEffects   */
export function* watchRequestCountries() {
  yield takeLatest(REQUEST_DELETE_CASE_PHOTO, deleteCasePicAsync);
}

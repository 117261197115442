/* eslint-disable no-console */
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { cleanLocalStorage, currentTimeInMiliseconds } from '../../utils';
import {
  LOGIN_REQUEST,
  LOGOUT_USER,
  REFRESH_TOKEN,
  VALID_SESSION,
  loginSuccess,
  loginFail,
  loginFailAct,
  setSession,
  FORCE_LOGOUT,
  RESET_PASSWORD_REQUEST,
  resetPasswordSuccess,
  resetPasswordFail,
  ACTIVATION_PASSWORD_REQUEST,
  activationPasswordSuccess,
  activationPasswordFail
} from '../actions/authActions';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import {
  login,
  logout,
  forceLogout,
  refresh,
  validSession,
  getResetPassword,
  getActivationPassword
} from '../../services/auth';
import { userSetData } from '../actions/userActions';
import { sendNotification, cleanNotifications } from 'helpers/helperNotifications';
import i18next from 'i18next';

function* loginWorker({ payload }) {
  try {
    const { credentials, method, handleValidSession } = payload;
    const response = yield call(login, credentials, method);
    if (response) {
      if (handleValidSession) yield handleValidSession(response);
    }
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(loginSuccess());

      const { data } = response.data;
      const tokenR = data.token;
      let user = {};
      let current = currentTimeInMiliseconds();
      console.log('Login at :>> ', new Date());
      user = {
        fullname: `${data.firstname} ${data.surname}`,
        email: data.email,
        roleId: data.role.id,
        groupName: data.role.groupName,
        groupId: data.role.groupId,
        publicName: data.role.publicName,
        validTo: data.validTo,
        validFrom: data.validFrom,
        customPermits: data.customPermits,
        claimsRole: data.claimsRole,
        claimsUser: data.claimsUser,
        userId: data.userId,
        expiresAt: data.expire + current,
        expireTime: data.expire,
        caseId: data.caseId ? data.caseId : null,
        temporary: data.temporary,
        timelife: data.timelife,
        userRole: `${data.role.groupName}${data.role.publicName}`,
        languageAbbreviation: data.languageAbbreviation
      };
      localStorage.setItem('tkn', tokenR);
      localStorage.setItem('tokenPic', data.tokenPic ? data.tokenPic : null);
      localStorage.setItem('user', JSON.stringify(user));
      console.log('Expires at ', data.expire / 1000 / 60, 'minutes after  now ');
      console.log('Expires time :>> ', new Date(data.expire + current));
      i18next.changeLanguage(data.languageAbbreviation);

      yield put(setSession());
      yield put(userSetData(user));
      let fullname = `${data.firstname} ${data.surname}`;
      yield cleanNotifications();
      yield sendNotification('success', `${i18next.t('system.welcome')} ${fullname} `);

      return response;
    } else if (typeof response !== 'undefined' && response.status === 400) {
      const { data } = response.data;
      const { message } = response.data;
      if (!data && message !== 'undefined' && (message.includes('sesión activa') || message.includes('sessão ativa') || message.includes('active session'))) {
        // console.error('aqui', response);
        // yield cleanNotifications();
        yield put(loginFailAct());
        yield LoginForm
        return response;
      }
    }
    yield put(loginSuccess(payload.credentials.username));
  } catch (error) {
    yield put(loginFail());
    console.error('error :', error);
  }
}

function* refreshWorker({ payload }) {
  const { token, method, handleBlock } = payload;
  console.log('Refresh toket at :>> ', new Date());

  try {
    const response = yield call(refresh, token, method);
    if (typeof response !== 'undefined' && response.status === 200) {
      const { data } = response.data;
      const tokenResponse = data.token;
      let user = {};
      let current = currentTimeInMiliseconds();
      user = {
        fullname: `${data.firstname} ${data.surname}`,
        email: data.email,
        roleId: data.role.id,
        groupName: data.role.groupName,
        groupId: data.role.groupId,
        publicName: data.role.publicName,
        validTo: data.validTo,
        validFrom: data.validFrom,
        customPermits: data.customPermits,
        claimsRole: data.claimsRole,
        claimsUser: data.claimsUser,
        userId: data.userId,
        expiresAt: data.expire + current,
        caseId: data.caseId ? data.caseId : null,
        temporary: data.temporary,
        timelife: data.timelife,
        userRole: `${data.role.groupName}${data.role.publicName}`,
        languageAbbreviation: data.languageAbbreviation
      };
      localStorage.setItem('tkn', tokenResponse);
      localStorage.setItem('tokenPic', data.tokenPic ? data.tokenPic : null);
      localStorage.setItem('user', JSON.stringify(user));
      yield put(userSetData(user));
      console.log('New expires time :>> ', new Date(data.expire + current));
      if (response.data.message) {
        if (handleBlock) yield handleBlock(response.data.message);
      }
      // yield put(setSession());
      // yield put(userSetData(user));
      //return response;
    } else {
      yield localStorage.removeItem('tkn');
      yield localStorage.removeItem('user');
      if (handleBlock) yield handleBlock('no response');
    }
    // yield put(loginSuccess());
  } catch (error) {
    if (handleBlock) yield handleBlock(error);
    yield put(loginFail());
    console.log('error :', error);
  }
}

const helperClean = () => {
  cleanLocalStorage();
};

function* logoutWorker() {
  try {
    const response = yield call(logout);
    yield cleanNotifications();
    if (typeof response !== 'undefined' && response.status === 200) {
      sendNotification('info', response.data.message);
    } else if (response.status === 401) {
      sendNotification('info', 'Sesión cerrada correctamente');
    }

    helperClean();
    //yield put(logOutUser());
  } catch (e) {
    yield localStorage.removeItem('tkn');
    yield localStorage.removeItem('user');
    //  yield put(logOutUser());
  }
}

function* forceLogoutWorker({ payload }) {
  try {
    const { username } = payload;

    const response = yield call(forceLogout, { username });
    yield cleanNotifications();
    if (typeof response !== 'undefined' && response.status === 200) {
      sendNotification('success', 'Sesión cerrada');
    }
  } catch (e) {
    console.log(e);
    //  yield put(logOutUser());
  }
}

function* validSessionWorker({ payload }) {
  try {
    const { sessionId, handleValidSession } = payload;

    const response = yield call(validSession, { sessionId });
    yield cleanNotifications();
    if (typeof response !== 'undefined' && response.status === 200) {
      if (response.data) {
        cleanLocalStorage();
        window.location.replace('/');
        //if (handleValidSession) yield handleValidSession(response.data);
      }
    }
  } catch (e) {
    console.log(e);
    //  yield put(logOutUser());
  }
}

function* workResetPasswordAsync(action) {
  const { payload } = action;
  const { onSuccess, onError, dataRequest } = payload;
  try {
    const response = yield call(getResetPassword, dataRequest);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(resetPasswordSuccess());
      yield onSuccess(response.data?.message);
    } else {
      let errorMessage;
      if (response.data.message) {
        errorMessage = response.data?.message;
        yield onError(errorMessage);
      }
      yield put(resetPasswordFail());
    }
  } catch (error) {
    yield put(resetPasswordFail());
    console.error('error', error);
  }
}

function* workActivationPasswordAsync(action) {
  const { payload } = action;
  const { onSuccess, onError, dataRequest } = payload;
  try {
    const response = yield call(getActivationPassword, dataRequest);
    if (typeof response !== 'undefined' && response.status === 200) {
      yield put(activationPasswordSuccess());
      yield onSuccess(response.data?.message);
      sendNotification('success', response.data?.message);
    } else if (response.status === 404) {
      let errorMessage = 'Error en el servicio, intenta mas tarde';
      yield onError(errorMessage);
      yield put(activationPasswordFail());
    } else {
      let errorMessage;
      if (response.data.message) {
        errorMessage = response.data?.message;
        yield onError(errorMessage);
      }
      yield put(activationPasswordFail());
    }
  } catch (error) {
    yield put(resetPasswordFail());
    console.error('error', error);
  }
}

export function* watchActivationPass() {
  yield takeEvery(ACTIVATION_PASSWORD_REQUEST, workActivationPasswordAsync);
}

export function* watchChangePass() {
  yield takeEvery(RESET_PASSWORD_REQUEST, workResetPasswordAsync);
}

export function* watchLogin() {
  yield takeEvery(LOGIN_REQUEST, loginWorker);
}

export function* watchLogOut() {
  yield takeLatest(LOGOUT_USER, logoutWorker);
}

export function* watchRefresh() {
  yield takeLatest(REFRESH_TOKEN, refreshWorker);
}

export function* watchValidSession() {
  yield takeEvery(VALID_SESSION, validSessionWorker);
}

export function* watchForceLogout() {
  yield takeLatest(FORCE_LOGOUT, forceLogoutWorker);
}

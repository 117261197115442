import { takeLatest, put, call } from 'redux-saga/effects';
import {
  DOWNLOAD_REPORT_DYNAMIC,
  DOWNLOAD_REPORT_EXCEL_REQUEST,
  downloadReportDynamicSucess,
} from 'redux/actions/advertActions';
import { reportBidsAdvert, reportExcelAdvert } from 'services/advertsReports';
import { sendNotification } from 'helpers/helperNotifications';
import { responseOK } from 'utils';
import { MSG_001,MSG_002 } from 'utils/messages';
import i18next from 'i18next';
export function* downloadReportAdvertsWorker(action) {
  try {
    const { payload } = action;
    const { name, dataSearch } = payload;
    const response = yield call(reportBidsAdvert,name,dataSearch);    
    if (responseOK(response)) {
      yield put(downloadReportDynamicSucess(response.data.data));      


      sendNotification('success', MSG_001);
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();

    }else
    {
      yield put(downloadReportDynamicSucess({}));    
      sendNotification('error', MSG_002); 
    }
  } catch (e) {
    sendNotification('error', e);
  }
}

export function* downloadExcelReportWorker(action) {
  try {
    const { payload } = action;
    const { name, dataSearch, reportType } = payload;
    const response = yield call(reportExcelAdvert,name,dataSearch, reportType);
    if(responseOK(response)){
      sendNotification('success',  i18next.t('on.report.queued'));
    }else{
      sendNotification('error', i18next.t('MSG_002'));
    }
  } catch (error) {
    sendNotification('error', i18next.t('MSG_002'));
  }
};

export function* watchDownloadReportAdverts() {
  yield takeLatest(DOWNLOAD_REPORT_DYNAMIC, downloadReportAdvertsWorker);
}

export function* watchDownloadExcelReport(){
  yield takeLatest(DOWNLOAD_REPORT_EXCEL_REQUEST, downloadExcelReportWorker);
}
